import CcCategory from '../category/cc-category';
import CcImage from '../image/cc-image';
import CcNutrition from '../nutrition/cc-nutrition';
import CcPackagingtype from '@/main/webapp/resources/src/vue/domain/product/cc-packagingtype';
import CcBrand from '@/main/webapp/resources/src/vue/domain/brand/cc-brand';
import { CcProductAttribute } from '@/main/webapp/resources/src/vue/domain/product/cc-productattribute';
import CcProductcategory from '@/main/webapp/resources/src/vue/domain/product/cc-productcategory';
import CcNutritionnumeric from '@/main/webapp/resources/src/vue/domain/nutritionnumeric/cc-nutritionnumeric';
import CcCountry from '@/main/webapp/resources/src/vue/domain/country/cc-country';

export default class CcProduct {
	private _unit: string;
	private _contentUnit: string;
	private _code: string;
	private _description: string;
	private _packageType: CcPackagingtype;
	private _itemsPerPackage: number = 0;
	private _packageOnly: boolean = false;
	private _customerDescription: string = '';
	private _customerUnit: string = '';
	private _averageWeight: string = '';
	private _primaryImage: CcImage = null;
	private _additives: Array<string> = [];
	private _alias: string = '';
	private _allergens: Array<string> = [];
	private _alternativeCode: string = '';
	private _attributes: Array<CcProductAttribute> = [];
	private _biological: boolean = false;
	private _brands: Array<string> = [];
	private _creationDate: string = '';
	private _creationUser: string = '';
	private _features: Array<string> = [];
	private _ingredients: Array<string> = [];
	private _lactoseFree: boolean = false;
	private _lastModifiedDate: string = '';
	private _lastModifiedUser: string = '';
	private _hundredPercentItalian: boolean = false;
	private _glutenFree: boolean = false;
	private _gtin: string = '';
	private _palmOilFree: boolean = false;
	private _published: boolean = false;
	private _recyclingNotes: Array<string> = [];
	private _saltFree: boolean = false;
	private _sugarFree: boolean = false;
	private _vegetable: boolean = false;
	private _wholemeal: boolean = false;
	private _yeastFree: boolean = false;
	private _images: Array<CcImage> = [];
	private _ecrCategory: CcCategory = null;
	private _numericNutrition: CcNutritionnumeric = null;
	private _textualNutrition: CcNutrition = null;
	private _brand: CcBrand = null;
	private _caliber: string = null;
	private _contentQuantity: number = null;
	private _countryOfOrigin: CcCountry = null;
	private _fruitAndVegetableCategory: CcProductcategory = null;
	private _marketingDescription: string = null;
	private _otherInformation: string = null;
	private _salesDescription: string = null;
	private _storage: string = null;
	private _maxDeliveryQuantity: number = null;
	private _maxCollectQuantity: number = null;

	constructor (
		code: string,
		alias: string,
		description: string,
		unit: string,
		contentUnit: string,
		itemsPerPackage: number,
		packageType: CcPackagingtype,
		packageOnly: boolean
	) {
		this._code = code;
		this._alias = alias;
		this._description = description;
		this._unit = unit;
		this._contentUnit = contentUnit;
		this._itemsPerPackage = itemsPerPackage;
		this._packageType = packageType;
		this._packageOnly = packageOnly;
	}

	get additives (): Array<string> {
		return this._additives;
	}

	set additives (additivies: Array<string>) {
		this._additives = additivies;
	}

	get alias (): string {
		return this._alias;
	}

	set alias (alias: string) {
		this._alias = alias;
	}

	get allergens (): Array<string> {
		return this._allergens;
	}

	set allergens (allergens: Array<string>) {
		this._allergens = allergens;
	}

	get alternativeCode (): string {
		return this._alternativeCode;
	}

	set alternativeCode (alternativeCode: string) {
		this._alternativeCode = alternativeCode;
	}

	get attributes (): Array<CcProductAttribute> {
		return this._attributes;
	}

	set attributes (attributes: Array<CcProductAttribute>) {
		this._attributes = attributes;
	}

	get averageWeight (): string {
		return this._averageWeight;
	}

	set averageWeight (averageWeight: string) {
		this._averageWeight = averageWeight;
	}

	get biological (): boolean {
		return this._biological;
	}

	set biological (biological: boolean) {
		this._biological = biological;
	}

	get brands (): Array<string> {
		return this._brands;
	}

	set brands (brands: Array<string>) {
		this._brands = brands;
	}

	get code (): string {
		return this._code;
	}

	set code (code: string) {
		this._code = code;
	}

	get creationDate (): string {
		return this._creationDate;
	}

	set creationDate (creationDate: string) {
		this._creationDate = creationDate;
	}

	get creationUser (): string {
		return this._creationUser;
	}

	set creationUser (creationUser: string) {
		this._creationUser = creationUser;
	}

	get customerDescription (): string {
		return this._customerDescription;
	}

	set customerDescription (customerDescription: string) {
		this._customerDescription = customerDescription;
	}

	get customerUnit (): string {
		return this._customerUnit;
	}

	set customerUnit (customerUnit: string) {
		this._customerUnit = customerUnit;
	}

	get description (): string {
		return this._description;
	}

	set description (description: string) {
		this._description = description;
	}

	get features (): Array<string> {
		return this._features;
	}

	set features (features: Array<string>) {
		this._features = features;
	}

	get images (): Array<CcImage> {
		return this._images;
	}

	set images (images: Array<CcImage>) {
		this._images = images;
	}

	get ingredients (): Array<string> {
		return this._ingredients;
	}

	set ingredients (ingredients: Array<string>) {
		this._ingredients = ingredients;
	}

	get itemsPerPackage (): number {
		return this._itemsPerPackage;
	}

	set itemsPerPackage (itemsPerPackage: number) {
		this._itemsPerPackage = itemsPerPackage;
	}

	get lactoseFree (): boolean {
		return this._lactoseFree;
	}

	set lactoseFree (lactoseFree: boolean) {
		this._lactoseFree = lactoseFree;
	}

	get lastModifiedDate (): string {
		return this._lastModifiedDate;
	}

	set lastModifiedDate (lastModifiedDate: string) {
		this._lastModifiedDate = lastModifiedDate;
	}

	get lastModifiedUser (): string {
		return this._lastModifiedUser;
	}

	set lastModifiedUser (lastModifiedUser: string) {
		this._lastModifiedUser = lastModifiedUser;
	}

	get hundredPercentItalian (): boolean {
		return this._hundredPercentItalian;
	}

	set hundredPercentItalian (hundredPercentItalian: boolean) {
		this._hundredPercentItalian = hundredPercentItalian;
	}

	get glutenFree (): boolean {
		return this._glutenFree;
	}

	set glutenFree (glutenFree: boolean) {
		this._glutenFree = glutenFree;
	}

	get gtin (): string {
		return this._gtin;
	}

	set gtin (gtin: string) {
		this._gtin = gtin;
	}

	get packageOnly (): boolean {
		return this._packageOnly;
	}

	set packageOnly (packageOnly: boolean) {
		this._packageOnly = packageOnly;
	}

	get packageType (): CcPackagingtype {
		return this._packageType;
	}

	set packageType (packageType: CcPackagingtype) {
		this._packageType = packageType;
	}

	get palmOilFree (): boolean {
		return this._palmOilFree;
	}

	set palmOilFree (palmOilFree: boolean) {
		this._palmOilFree = palmOilFree;
	}

	get published (): boolean {
		return this._published;
	}

	set published (published: boolean) {
		this._published = published;
	}

	get recyclingNotes (): Array<string> {
		return this._recyclingNotes;
	}

	set recyclingNotes (recyclingNotes: Array<string>) {
		this._recyclingNotes = recyclingNotes;
	}

	get saltFree (): boolean {
		return this._saltFree;
	}

	set saltFree (saltFree: boolean) {
		this._saltFree = saltFree;
	}

	get sugarFree (): boolean {
		return this._sugarFree;
	}

	set sugarFree (sugarFree: boolean) {
		this._sugarFree = sugarFree;
	}

	get unit (): string {
		return this._unit;
	}

	set unit (unit: string) {
		this._unit = unit;
	}

	get contentUnit (): string {
		return this._contentUnit;
	}

	set contentUnit (contentUnit: string) {
		this._contentUnit = contentUnit;
	}

	get vegetable (): boolean {
		return this._vegetable;
	}

	set vegetable (vegetable: boolean) {
		this._vegetable = vegetable;
	}

	get wholemeal (): boolean {
		return this._wholemeal;
	}

	set wholemeal (wholemeal: boolean) {
		this._wholemeal = wholemeal;
	}

	get yeastFree (): boolean {
		return this._yeastFree;
	}

	set yeastFree (yeastFree: boolean) {
		this._yeastFree = yeastFree;
	}

	get ecrCategory (): CcCategory {
		return this._ecrCategory;
	}

	set ecrCategory (ecrCategory: CcCategory) {
		this._ecrCategory = ecrCategory;
	}

	get primaryImage (): CcImage {
		return this._primaryImage;
	}

	set primaryImage (primaryImage: CcImage) {
		this._primaryImage = primaryImage;
	}

	get numericNutrition (): CcNutritionnumeric {
		return this._numericNutrition;
	}

	set numericNutrition (numericNutrition: CcNutritionnumeric) {
		this._numericNutrition = numericNutrition;
	}

	get textualNutrition (): CcNutrition {
		return this._textualNutrition;
	}

	set textualNutrition (textualNutrition: CcNutrition) {
		this._textualNutrition = textualNutrition;
	}

	get brand (): CcBrand {
		return this._brand;
	}

	set brand (brand: CcBrand) {
		this._brand = brand;
	}

	get caliber (): string {
		return this._caliber;
	}

	set caliber (caliber: string) {
		this._caliber = caliber;
	}

	get contentQuantity (): number {
		return this._contentQuantity;
	}

	set contentQuantity (contentQuantity: number) {
		this._contentQuantity = contentQuantity;
	}

	get countryOfOrigin (): CcCountry {
		return this._countryOfOrigin;
	}

	set countryOfOrigin (countryOfOrigin: CcCountry) {
		this._countryOfOrigin = countryOfOrigin;
	}

	get fruitAndVegetableCategory (): CcProductcategory {
		return this._fruitAndVegetableCategory;
	}

	set fruitAndVegetableCategory (fruitAndVegetableCategory: CcProductcategory) {
		this._fruitAndVegetableCategory = fruitAndVegetableCategory;
	}

	get marketingDescription (): string {
		return this._marketingDescription;
	}

	set marketingDescription (marketingDescription: string) {
		this._marketingDescription = marketingDescription;
	}

	get otherInformation (): string {
		return this._otherInformation;
	}

	set otherInformation (otherInformation: string) {
		this._otherInformation = otherInformation;
	}

	get salesDescription (): string {
		return this._salesDescription;
	}

	set salesDescription (salesDescription: string) {
		this._salesDescription = salesDescription;
	}

	get storage (): string {
		return this._storage;
	}

	set storage (storage: string) {
		this._storage = storage;
	}

	get maxDeliveryQuantity (): number {
		return this._maxDeliveryQuantity;
	}

	set maxDeliveryQuantity (maxDeliveryQuantity: number) {
		this._maxDeliveryQuantity = maxDeliveryQuantity;
	}

	get maxCollectQuantity (): number {
		return this._maxCollectQuantity;
	}

	set maxCollectQuantity (maxCollectQuantity: number) {
		this._maxCollectQuantity = maxCollectQuantity;
	}
}
