import Router from 'vue-router';
import { Utils } from '../utils/helpers';
const CcPageError = () => import(/* webpackChunkName: 'cc-page-error' */ '../../components/cc-page-error/cc-page-error.vue');
const CcPageComponent = () => import(/* webpackChunkName: 'cc-page' */ '../../components/cc-page/cc-page.vue');
const CcPageInformationComponent = () => import(/* webpackChunkName: 'cc-page-information' */ '../../components/cc-page-information/cc-page-information.vue');
const CcPageStoreList = () => import(/* webpackChunkName: 'cc-page-store-list' */ '../../components/cc-page-store-list/cc-page-store-list.vue');
const CcPageProductComponent = () => import(/* webpackChunkName: 'cc-page-product' */ '../../components/cc-page-product/cc-page-product.vue');
const CcPageOrderComponent = () => import(/* webpackChunkName: 'cc-page-order' */ '../../components/cc-page-order/cc-page-order.vue');
const CcPageCurrentOrderComponent = () => import(/* webpackChunkName: 'cc-page-current-order' */ '../../components/cc-page-current-order/cc-page-current-order.vue');
const CcPageOrdersListComponent = () => import(/* webpackChunkName: 'cc-page-orders-list' */ '../../components/cc-page-orders-list/cc-page-orders-list.vue');
const CcPageOrderConfirmComponent = () => import(/* webpackChunkName: 'cc-page-order-confirm' */ '../../components/cc-page-order-confirm/cc-page-order-confirm.vue');
const CcPagePasswordChangeComponent = () => import(/* webpackChunkName: 'cc-page-password-change' */ '../../components/cc-page-password-change/cc-page-password-change.vue');
const CcPagePasswordChangeConfirmComponent = () => import(/* webpackChunkName: 'cc-page-password-change-confirm' */ '../../components/cc-page-password-change-confirm/cc-page-password-change-confirm.vue');
const CcPagePasswordExpiredComponent = () => import(/* webpackChunkName: 'cc-page-password-expired' */ '../../components/cc-page-password-expired/cc-page-password-expired.vue');
const CcPagePasswordForgottenComponent = () => import(/* webpackChunkName: 'cc-page-password-forgotten' */ '../../components/cc-page-password-forgotten/cc-page-password-forgotten.vue');
const CcPagePasswordForgottenConfirmComponent = () => import(/* webpackChunkName: 'cc-page-password-forgotten-confirm' */ '../../components/cc-page-password-forgotten-confirm/cc-page-password-forgotten-confirm.vue');
const CcPageProfileUpdateComponent = () => import(/* webpackChunkName: 'cc-page-profile-update' */ '../../components/cc-page-profile-update/cc-page-profile-update.vue');
const CcPageProfileUpdateConfirmComponent = () => import(/* webpackChunkName: 'cc-page-profile-update-confirm' */ '../../components/cc-page-profile-update-confirm/cc-page-profile-update-confirm.vue');
const CcPageRegistrationComponent = () => import(/* webpackChunkName: 'cc-page-registration' */ '../../components/cc-page-registration/cc-page-registration.vue');
const CcPageRegistrationConfirmComponent = () => import(/* webpackChunkName: 'cc-page-registration-confirm' */ '../../components/cc-page-registration-confirm/cc-page-registration-confirm.vue');
const CcPageLoginComponent = () => import(/* webpackChunkName: 'cc-page-login' */ '../../components/cc-page-login/cc-page-login.vue');
const CcPageInformationConfirmComponent = () => import(/* webpackChunkName: 'cc-page-information-confirm' */ '../../components/cc-page-information-confirm/cc-page-information-confirm.vue');

export default new Router({
	mode: Utils.routingMode(),
	base: Utils.getContextPath(),
	scrollBehavior () {
		return { x: 0, y: 0 };
	},
	routes: [
		{
			path: '*',
			redirect: { name: 'home' }
		},
		{
			name: 'home',
			path: '/',
			component: Utils.getCustomComponent('cc-page-home'),
			meta: {
				title: 'cc_page_home_header_title',
				robots: 'index, follow',
				description: 'cc_page_home_header_meta_description'
			}
		},
		{
			name: 'current-order',
			path: '/current-order',
			component: CcPageCurrentOrderComponent,
			meta: {
				title: 'cc_page_current_order_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'customer-shipping',
			path: '/customer-shipping',
			component: () => import(/* webpackChunkName: 'cc-page-customer-shipping' */ '../../components/cc-page-customer-shipping/cc-page-customer-shipping.vue'),
			meta: {
				title: 'cc_page_customer_shipping_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'orders',
			path: '/orders',
			component: CcPageOrdersListComponent,
			meta: {
				title: 'cc_page_orders_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'order',
			path: '/orders/:orderId',
			component: CcPageOrderComponent,
			meta: {
				title: 'cc_page_order_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'order-confirm',
			path: '/orders/:orderId/confirm',
			component: CcPageOrderConfirmComponent,
			meta: {
				title: 'cc_page_confirm_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'products',
			path: '/products/:productAlias',
			component: CcPageProductComponent,
			meta: {
				title: '',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'password-change',
			path: '/password-change',
			component: CcPagePasswordChangeComponent,
			meta: {
				title: 'cc_page_password_change_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'password-change-confirm',
			path: '/password-change/confirm',
			component: CcPagePasswordChangeConfirmComponent,
			meta: {
				title: 'cc_page_password_change_confirm_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'password-expired',
			path: '/password-expired',
			component: CcPagePasswordExpiredComponent,
			meta: {
				title: 'cc_page_password_expired_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'password-forgotten',
			path: '/password-forgotten',
			component: CcPagePasswordForgottenComponent,
			meta: {
				title: 'cc_page_password_forgotten_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'password-forgotten-confirm',
			path: '/password-forgotten/confirm',
			component: CcPagePasswordForgottenConfirmComponent,
			meta: {
				title: 'cc_page_password_forgotten_confirm_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'profile-update',
			path: '/profile-update',
			component: CcPageProfileUpdateComponent,
			meta: {
				title: 'cc_page_profile_update_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'profile-update-confirm',
			path: '/profile-update/confirm',
			component: CcPageProfileUpdateConfirmComponent,
			meta: {
				title: 'cc_page_profile_update_confirm_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'registration',
			path: '/registration',
			component: CcPageRegistrationComponent,
			meta: {
				title: 'cc_page_profile_registration_header_title',
				robots: 'index, follow',
				description: 'cc_page_profile_registration_header_meta_description'
			}
		},
		{
			name: 'registration-confirm',
			path: '/registration/confirm',
			component: CcPageRegistrationConfirmComponent,
			meta: {
				title: 'cc_page_profile_registration_confirm_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'login',
			path: '/login',
			component: CcPageLoginComponent,
			meta: {
				title: 'cc_page_login_header_title',
				robots: 'index, follow',
				description: 'cc_page_login_header_meta_description'
			}
		},
		{
			name: 'store-list',
			path: '/stores',
			component: CcPageStoreList,
			meta: {
				title: 'cc_page_store_list_header_title',
				robots: 'index, follow',
				description: 'cc_page_store_list_header_meta_description'
			}
		},
		{
			name: 'information',
			path: '/information',
			component: CcPageInformationComponent,
			meta: {
				title: 'cc_page_information_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'information-confirm',
			path: '/information/confirm',
			component: CcPageInformationConfirmComponent,
			meta: {
				title: 'cc_page_information_confirm_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		},
		{
			name: 'page',
			path: '/pages/:alias',
			component: CcPageComponent,
			meta: {
				title: '',
				robots: 'index, follow',
				description: ''
			}
		},
		{
			name: 'error',
			path: '/errors/:errorCode',
			component: CcPageError,
			meta: {
				title: 'cc_page_error_header_title',
				robots: 'noindex, nofollow',
				description: ''
			}
		}
	]
});
