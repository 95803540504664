import CcStoreStatus from '@/main/webapp/resources/src/vue/domain/store/cc-storestatus';
import CcDayHoursActivityRange from '@/main/webapp/resources/src/vue/domain/dayhoursactivityrange/cc-dayhoursactivityrange';
import CcProvince from '@/main/webapp/resources/src/vue/domain/province/cc-province';

export default class CcStore {
	private _name: string = '';
	private _code: string = '';
	private _status: CcStoreStatus;
	private _province: CcProvince;
	private _address: string = '';
	private _phone: string = '';
	private _fax: string = '';
	private _city: string = '';
	private _email: string = '';
	private _creationDate: string = '';
	private _creationUser: string = '';
	private _dismissDate: string = '';
	private _dismissUser: string = '';
	private _lastModifiedDate: string = '';
	private _lastModifiedUser: string = '';
	private _openingTimes: Array<CcDayHoursActivityRange> = [];
	private _pickingTimes: Array<CcDayHoursActivityRange> = [];
	private _availableForCollect: boolean;
	private _availableForDelivery: boolean;
	private _notes: string = '';
	private _postalCode: string = '';
	private _minimumCollectOrderAmount: number = 0;

	constructor (name: string, code: string, status: CcStoreStatus, province: CcProvince, city: string, address: string, phone: string, fax: string, email: string, availableForCollect: boolean, availableForDelivery: boolean, notes: string, postalCode: string) {
		this._name = name;
		this._code = code;
		this._status = status;
		this._province = province;
		this._city = city;
		this._address = address;
		this._phone = phone;
		this._fax = fax;
		this._email = email;
		this._availableForCollect = availableForCollect;
		this._availableForDelivery = availableForDelivery;
		this._notes = notes;
		this._postalCode = postalCode;
	}

	get name (): string {
		return this._name;
	}

	set name (name: string) {
		this._name = name;
	}

	get code (): string {
		return this._code;
	}

	set code (code: string) {
		this._code = code;
	}

	get status (): CcStoreStatus {
		return this._status;
	}

	set status (status: CcStoreStatus) {
		this._status = status;
	}

	get openingTimes (): Array<CcDayHoursActivityRange> {
		return this._openingTimes;
	}

	set openingTimes (openingTimes: Array<CcDayHoursActivityRange>) {
		this._openingTimes = openingTimes;
	}

	get pickingTimes (): Array<CcDayHoursActivityRange> {
		return this._pickingTimes;
	}

	set pickingTimes (pickingTimes: Array<CcDayHoursActivityRange>) {
		this._pickingTimes = pickingTimes;
	}

	get province (): CcProvince {
		return this._province;
	}

	set province (province: CcProvince) {
		this._province = province;
	}

	get address (): string {
		return this._address;
	}

	set address (address: string) {
		this._address = address;
	}

	get phone (): string {
		return this._phone;
	}

	set phone (phone: string) {
		this._phone = phone;
	}

	get fax (): string {
		return this._fax;
	}

	set fax (fax: string) {
		this._fax = fax;
	}

	get city (): string {
		return this._city;
	}

	set city (city: string) {
		this._city = city;
	}

	get email (): string {
		return this._email;
	}

	set email (email: string) {
		this._email = email;
	}

	get creationDate (): string {
		return this._creationDate;
	}

	set creationDate (creationDate: string) {
		this._creationDate = creationDate;
	}

	get creationUser (): string {
		return this._creationUser;
	}

	set creationUser (creationUser: string) {
		this._creationUser = creationUser;
	}

	get dismissDate (): string {
		return this._dismissDate;
	}

	set dismissDate (dismissDate: string) {
		this._dismissDate = dismissDate;
	}

	get dismissUser (): string {
		return this._dismissUser;
	}

	set dismissUser (dismissUser: string) {
		this._dismissUser = dismissUser;
	}

	get lastModifiedDate (): string {
		return this._lastModifiedDate;
	}

	set lastModifiedDate (lastModifiedDate: string) {
		this._lastModifiedDate = lastModifiedDate;
	}

	get lastModifiedUser (): string {
		return this._lastModifiedUser;
	}

	set lastModifiedUser (lastModifiedUser: string) {
		this._lastModifiedUser = lastModifiedUser;
	}

	get availableForCollect (): boolean {
		return this._availableForCollect;
	}

	set availableForCollect (availableForCollect: boolean) {
		this._availableForCollect = availableForCollect;
	}

	get availableForDelivery (): boolean {
		return this._availableForDelivery;
	}

	set availableForDelivery (availableForDelivery: boolean) {
		this._availableForDelivery = availableForDelivery;
	}

	get minimumCollectOrderAmount (): number {
		return this._minimumCollectOrderAmount;
	}

	set minimumCollectOrderAmount (minimumCollectOrderAmount: number) {
		this._minimumCollectOrderAmount = minimumCollectOrderAmount;
	}

	get notes (): string {
		return this._notes;
	}

	set notes (notes: string) {
		this._notes = notes;
	}

	get postalCode (): string {
		return this._postalCode;
	}

	set postalCode (postalCode: string) {
		this._postalCode = postalCode;
	}
}
