export default class CcImage {
	private _application: boolean;
	private _audio: boolean;
	private _creationDate: string;
	private _creationUser: string;
	private _displaySize: string;
	private _document: boolean;
	private _height: number;
	private _image: boolean;
	private _mimeType: string;
	private _name: string;
	private _pdf: boolean;
	private _presentation: boolean;
	private _size: number;
	private _spreadsheet: boolean;
	private _text: boolean;
	private _video: boolean;
	private _width: number;
	private _lastModifiedDate: string = null;
	private _lastModifiedUser: string = null;

	constructor (name: string) {
		this._name = name;
	}

	get application (): boolean {
		return this._application;
	}

	set application (application: boolean) {
		this._application = application;
	}

	get audio (): boolean {
		return this._audio;
	}

	set audio (audio: boolean) {
		this._audio = audio;
	}

	get creationDate (): string {
		return this._creationDate;
	}

	set creationDate (creationDate: string) {
		this._creationDate = creationDate;
	}

	get creationUser (): string {
		return this._creationUser;
	}

	set creationUser (creationUser: string) {
		this._creationUser = creationUser;
	}

	get lastModifiedDate (): string {
		return this._lastModifiedDate;
	}

	set lastModifiedDate (lastModifiedDate: string) {
		this._lastModifiedDate = lastModifiedDate;
	}

	get lastModifiedUser (): string {
		return this._lastModifiedUser;
	}

	set lastModifiedUser (lastModifiedUser: string) {
		this._lastModifiedUser = lastModifiedUser;
	}

	get displaySize (): string {
		return this._displaySize;
	}

	set displaySize (displaySize: string) {
		this._displaySize = displaySize;
	}

	get document (): boolean {
		return this._document;
	}

	set document (document: boolean) {
		this._document = document;
	}

	get height (): number {
		return this._height;
	}

	set height (height: number) {
		this._height = height;
	}

	get width (): number {
		return this._width;
	}

	set width (width: number) {
		this._width = width;
	}

	get size (): number {
		return this._size;
	}

	set size (size: number) {
		this._size = size;
	}

	get image (): boolean {
		return this._image;
	}

	set image (image: boolean) {
		this._image = image;
	}

	get text (): boolean {
		return this._text;
	}

	set text (text: boolean) {
		this._text = text;
	}

	get video (): boolean {
		return this._video;
	}

	set video (video: boolean) {
		this._video = video;
	}

	get presentation (): boolean {
		return this._presentation;
	}

	set presentation (presentation: boolean) {
		this._presentation = presentation;
	}

	get spreadsheet (): boolean {
		return this._spreadsheet;
	}

	set spreadsheet (spreadsheet: boolean) {
		this._spreadsheet = spreadsheet;
	}

	get pdf (): boolean {
		return this._pdf;
	}

	set pdf (pdf: boolean) {
		this._pdf = pdf;
	}

	get mimeType (): string {
		return this._mimeType;
	}

	set mimeType (mimeType: string) {
		this._mimeType = mimeType;
	}

	get name (): string {
		return this._name;
	}

	set name (name: string) {
		this._name = name;
	}
}
