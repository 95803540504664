
import 'reflect-metadata';
import { Container } from 'inversify';
import { CC_CONTAINER_TYPES } from './cc-container-types';
import { Api } from '../api/api';
import { ApiInterface } from '../api/interfaces/api-interface';
import Service from '../../service/cc-service';
import CcServiceInterface from '../../service/cc-service-interface';
import { CcApplication } from '../setup/cc-application';
import { CcApplicationInterface } from '../setup/cc-application-interface';

let container: Container = new Container();

container.bind<ApiInterface>(CC_CONTAINER_TYPES.ApiInterface).to(Api);
container.bind<CcServiceInterface>(CC_CONTAINER_TYPES.CcServiceInterface).to(Service);
container.bind<CcApplicationInterface>(CC_CONTAINER_TYPES.CcApplicationInterface).to(CcApplication);

export { container };
