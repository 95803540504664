import CcProduct from '../product/cc-product';

export default class CcProductstock {
	private _availability: number;
	private _available: boolean;
	private _contentUnitListPrice: number;
	private _contentUnitPrice: number;
	private _contentUnitSalePrice: number;
	private _cost: number;
	private _discount: number;
	private _listPrice: number;
	private _onSale: boolean;
	private _price: number;
	private _product: CcProduct;
	private _quantity: number;
	private _saleEndDate: string = null;
	private _salePrice: number = null;
	private _saleStartDate: string = null;
	private _updateDate: string = null;
	private _total: number = 0;

	constructor (
		availability: number,
		available: boolean,
		contentUnitListPrice: number,
		contentUnitPrice: number,
		contentUnitSalePrice: number,
		cost: number,
		discount: number,
		listPrice: number,
		salePrice: number,
		onSale: boolean,
		price: number,
		product: CcProduct,
		quantity: number
	) {
		this._availability = availability;
		this._available = available;
		this._contentUnitListPrice = contentUnitListPrice;
		this._contentUnitPrice = contentUnitPrice;
		this._contentUnitSalePrice = contentUnitSalePrice;
		this._cost = cost;
		this._discount = discount;
		this._listPrice = listPrice;
		this._salePrice = salePrice;
		this._onSale = onSale;
		this._price = price;
		this._product = product;
		this._quantity = quantity;
	}

	get availability (): number {
		return this._availability;
	}

	set availability (availability: number) {
		this._availability = availability;
	}

	get available (): boolean {
		return this._available;
	}

	set available (available: boolean) {
		this._available = available;
	}

	get contentUnitListPrice (): number {
		return this._contentUnitListPrice;
	}

	set contentUnitListPrice (contentUnitListPrice: number) {
		this._contentUnitListPrice = contentUnitListPrice;
	}

	get contentUnitPrice (): number {
		return this._contentUnitPrice;
	}

	set contentUnitPrice (contentUnitPrice: number) {
		this._contentUnitPrice = contentUnitPrice;
	}

	get contentUnitSalePrice (): number {
		return this._contentUnitSalePrice;
	}

	set contentUnitSalePrice (contentUnitSalePrice: number) {
		this._contentUnitSalePrice = contentUnitSalePrice;
	}

	get cost (): number {
		return this._cost;
	}

	set cost (cost: number) {
		this._cost = cost;
	}

	get discount (): number {
		return this._discount;
	}

	set discount (discount: number) {
		this._discount = discount;
	}

	get listPrice (): number {
		return this._listPrice;
	}

	set listPrice (listPrice: number) {
		this._listPrice = listPrice;
	}

	get onSale (): boolean {
		return this._onSale;
	}

	set onSale (onSale: boolean) {
		this._onSale = onSale;
	}

	get price (): number {
		return this._price;
	}

	set price (price: number) {
		this._price = price;
	}

	get product (): CcProduct {
		return this._product;
	}

	set product (product: CcProduct) {
		this._product = product;
	}

	get quantity (): number {
		return this._quantity;
	}

	set quantity (quantity: number) {
		this._quantity = quantity;
	}

	get saleEndDate (): string {
		return this._saleEndDate;
	}

	set saleEndDate (saleEndDate: string) {
		this._saleEndDate = saleEndDate;
	}

	get salePrice (): number {
		return this._salePrice;
	}

	set salePrice (salePrice: number) {
		this._salePrice = salePrice;
	}

	get saleStartDate (): string {
		return this._saleStartDate;
	}

	set saleStartDate (saleStartDate: string) {
		this._saleStartDate = saleStartDate;
	}

	get updateDate (): string {
		return this._updateDate;
	}

	set updateDate (updateDate: string) {
		this._updateDate = updateDate;
	}

	get total (): number {
		return this._total;
	}

	set total (total: number) {
		this._total = total;
	}

	get isAvailable (): boolean {
		return this.product.packageOnly ? this.availability >= this.product.itemsPerPackage && this.available : this.available;
	}
}
