import CcProvince from '@/main/webapp/resources/src/vue/domain/province/cc-province';
import CcStore from '@/main/webapp/resources/src/vue/domain/store/cc-store';
import CcCustomerstatus from '@/main/webapp/resources/src/vue/domain/user/cc-customerstatus';

export default class CcCustomer {
	private _code: string = null;
	private _name: string = null;
	private _address: string = null;
	private _city: string = null;
	private _postalCode: string = null;
	private _province: CcProvince = null;
	private _status: CcCustomerstatus;
	private _store: CcStore;
	private _shippingDestinations: Array<CcCustomer> = [];
	private _hasRoute = true;
	private _routeStore: CcStore = null;
	private _phone: string = '';
	private _mobile: string = '';
	private _notes: string = '';
	private _email: string = '';
	private _fax: string = '';
	private _minimumDeliveryAmount: number = null;
	private _vatNumber: string = '';

	constructor (code: string, name: string, address: string, city: string, postalCode: string, province: CcProvince, status: CcCustomerstatus, phone: string, mobile: string, email: string, notes: string, fax: string, minimumDeliveryAmount: number) {
		this._code = code;
		this._name = name;
		this._address = address;
		this._city = city;
		this._postalCode = postalCode;
		this._province = province;
		this._status = status;
		this._phone = phone;
		this._mobile = mobile;
		this._notes = notes;
		this._email = email;
		this._fax = fax;
		this._minimumDeliveryAmount = minimumDeliveryAmount;
	}

	get code (): string {
		return this._code;
	}

	set code (value: string) {
		this._code = value;
	}

	get name (): string {
		return this._name;
	}

	set name (value: string) {
		this._name = value;
	}

	get address (): string {
		return this._address;
	}

	set address (value: string) {
		this._address = value;
	}

	get city (): string {
		return this._city;
	}

	set city (value: string) {
		this._city = value;
	}

	get postalCode (): string {
		return this._postalCode;
	}

	set postalCode (value: string) {
		this._postalCode = value;
	}

	get province (): CcProvince {
		return this._province;
	}

	set province (province: CcProvince) {
		this._province = province;
	}

	get status (): CcCustomerstatus {
		return this._status;
	}

	set status (status: CcCustomerstatus) {
		this._status = status;
	}

	get store (): CcStore {
		return this._store;
	}

	set store (store: CcStore) {
		this._store = store;
	}

	get shippingDestinations (): Array<CcCustomer> {
		return this._shippingDestinations;
	}

	set shippingDestinations (shippingDestinations: Array<CcCustomer>) {
		this._shippingDestinations = shippingDestinations;
	}

	get hasRoute (): boolean {
		return this._hasRoute;
	}

	set hasRoute (hasRoute: boolean) {
		this._hasRoute = hasRoute;
	}

	get routeStore (): CcStore {
		return this._routeStore;
	}

	set routeStore (value: CcStore) {
		this._routeStore = value;
	}

	get phone (): string {
		return this._phone;
	}

	set phone (value: string) {
		this._phone = value;
	}

	get mobile (): string {
		return this._mobile;
	}

	set mobile (value: string) {
		this._mobile = value;
	}

	get email (): string {
		return this._email;
	}

	set email (value: string) {
		this._email = value;
	}

	get notes (): string {
		return this._notes;
	}

	set notes (value: string) {
		this._notes = value;
	}

	get fax (): string {
		return this._fax;
	}

	set fax (value: string) {
		this._fax = value;
	}

	get minimumDeliveryAmount (): number {
		return this._minimumDeliveryAmount;
	}

	set minimumDeliveryAmount (value: number) {
		this._minimumDeliveryAmount = value;
	}

	get vatNumber (): string {
		return this._vatNumber;
	}

	set vatNumber (value: string) {
		this._vatNumber = value;
	}

	get fullAddress () : string {
		let fullAddress: string = '';
		fullAddress = this.address ? `${fullAddress}${this.address}` : fullAddress;
		fullAddress = this.postalCode ? `${fullAddress} - ${this.postalCode}` : fullAddress;
		fullAddress = this.city ? `${fullAddress} - ${this.city}` : fullAddress;
		fullAddress = this.province ? `${fullAddress} (${this.province.code})` : fullAddress;
		return fullAddress;
	}
}
