import CcOrderType from '@/main/webapp/resources/src/vue/domain/order/cc-ordertype';
import CcStore from '@/main/webapp/resources/src/vue/domain/store/cc-store';
import CcProductStock from '@/main/webapp/resources/src/vue/domain/productstock/cc-productstock';
import CcOrderstatus from '@/main/webapp/resources/src/vue/domain/order/cc-orderstatus';
import CcDiscount from '@/main/webapp/resources/src/vue/domain/discount/cc-discount';
import CcPreparationCost from '@/main/webapp/resources/src/vue/domain/preparationcost/cc-preparationcost';
import CcCustomer from '@/main/webapp/resources/src/vue/domain/user/cc-customer';

export default class CcOrder {
	private _availableForDelivery: boolean;
	private _availableForCollect: boolean;
	private _type: CcOrderType = null;
	private _collectDate: string = null;
	private _deliveryDate: string = null;
	private _store: CcStore = null;
	private _items: Array<CcProductStock> = [];
	private _total: number = 0;
	private _totalAmount: number = 0;
	private _totalDiscount: number = 0;
	private _status: CcOrderstatus = null;
	private _number: number = 0;
	private _sendDate: string = null;
	private _discounts: Array<CcDiscount> = [];
	private _preparationCost: CcPreparationCost = null;
	private _shippingCost: CcPreparationCost = null;
	private _shippingInformation: CcCustomer = null;
	private _customer: CcCustomer = null;

	constructor (availableForDelivery: boolean, availableForCollect: boolean) {
		this._availableForDelivery = availableForDelivery;
		this._availableForCollect = availableForCollect;
	}

	get type (): CcOrderType {
		return this._type;
	}

	set type (orderType: CcOrderType) {
		this._type = orderType;
	}

	get collectDate (): string {
		return this._collectDate;
	}

	set collectDate (collectDate: string) {
		this._collectDate = collectDate;
	}

	get deliveryDate (): string {
		return this._deliveryDate;
	}

	set deliveryDate (deliveryDate: string) {
		this._deliveryDate = deliveryDate;
	}

	get store (): CcStore {
		return this._store;
	}

	set store (store: CcStore) {
		this._store = store;
	}

	get items (): Array<CcProductStock> {
		return this._items;
	}

	set items (items: Array<CcProductStock>) {
		this._items = items;
	}

	get total (): number {
		return this._total;
	}

	set total (total: number) {
		this._total = total;
	}

	get totalAmount (): number {
		return this._totalAmount;
	}

	set totalAmount (totalAmount: number) {
		this._totalAmount = totalAmount;
	}

	get totalDiscount (): number {
		return this._totalDiscount;
	}

	set totalDiscount (totalDiscount: number) {
		this._totalDiscount = totalDiscount;
	}

	get status (): CcOrderstatus {
		return this._status;
	}

	set status (status: CcOrderstatus) {
		this._status = status;
	}

	get number (): number {
		return this._number;
	}

	set number (number: number) {
		this._number = number;
	}

	get sendDate (): string {
		return this._sendDate;
	}

	set sendDate (sendDate: string) {
		this._sendDate = sendDate;
	}

	get availableForDelivery (): boolean {
		return this._availableForDelivery;
	}

	set availableForDelivery (availableForDelivery: boolean) {
		this._availableForDelivery = availableForDelivery;
	}

	get availableForCollect (): boolean {
		return this._availableForCollect;
	}

	set availableForCollect (availableForCollect: boolean) {
		this._availableForCollect = availableForCollect;
	}

	get discounts (): Array<any> {
		return this._discounts;
	}

	set discounts (discounts: Array<any>) {
		this._discounts = discounts;
	}

	get preparationCost (): CcPreparationCost {
		return this._preparationCost;
	}

	set preparationCost (preparationCost: CcPreparationCost) {
		this._preparationCost = preparationCost;
	}

	get shippingCost (): CcPreparationCost {
		return this._shippingCost;
	}

	set shippingCost (shippingCost: CcPreparationCost) {
		this._shippingCost = shippingCost;
	}

	get shippingInformation (): CcCustomer {
		return this._shippingInformation;
	}

	set shippingInformation (shippingInformation: CcCustomer) {
		this._shippingInformation = shippingInformation;
	}

	get customer (): CcCustomer {
		return this._customer;
	}

	set customer (customer: CcCustomer) {
		this._customer = customer;
	}
}
