import CcBannerAreaCode from './cc-banner-area-code';
import CcBanner from './cc-banner';

export default class CcBannerArea {
	private _code: CcBannerAreaCode;
	private _name: string;
	private _position: number;
	private _width: number;
	private _height: number;
	private _displayBanners: Array<String> = [];
	private _displayBannerCount: number;
	private _banners: Array<CcBanner> = [];
	private _bannerCount: number = null;
	private _creationDate: string = null;
	private _creationUser: string = null;
	private _lastModifiedDate: string = null;
	private _lastModifiedUser: string = null;

	constructor (code: CcBannerAreaCode, name: string, position: number, width: number, height: number, displayBannerCount: number, displayBanners: Array<any>) {
		this._code = code;
		this._name = name;
		this._position = position;
		this._width = width;
		this._height = height;
		this._displayBannerCount = displayBannerCount;
		this._displayBanners = displayBanners;
	}

	get code (): CcBannerAreaCode {
		return this._code;
	}

	set code (code: CcBannerAreaCode) {
		this._code = code;
	}

	get name (): string {
		return this._name;
	}

	set name (name: string) {
		this._name = name;
	}

	get position (): number {
		return this._position;
	}

	set position (position: number) {
		this._position = position;
	}

	get width (): number {
		return this._width;
	}

	set width (width: number) {
		this._width = width;
	}

	get height (): number {
		return this._height;
	}

	set height (height: number) {
		this._height = height;
	}

	get displayBanners (): Array<String> {
		return this._displayBanners;
	}

	set displayBanners (displayBanners: Array<String>) {
		this._displayBanners = displayBanners;
	}

	get displayBannerCount (): number {
		return this._displayBannerCount;
	}

	set displayBannerCount (displayBannerCount: number) {
		this._displayBannerCount = displayBannerCount;
	}

	get banners (): Array<CcBanner> {
		return this._banners;
	}

	set banners (banners: Array<CcBanner>) {
		this._banners = banners;
	}

	get bannerCount (): number {
		return this._bannerCount;
	}

	set bannerCount (bannerCount: number) {
		this._bannerCount = bannerCount;
	}

	get creationDate (): string {
		return this._creationDate;
	}

	set creationDate (creationDate: string) {
		this._creationDate = creationDate;
	}

	get creationUser (): string {
		return this._creationUser;
	}

	set creationUser (creationUser: string) {
		this._creationUser = creationUser;
	}

	get lastModifiedDate (): string {
		return this._lastModifiedDate;
	}

	set lastModifiedDate (lastModifiedDate: string) {
		this._lastModifiedDate = lastModifiedDate;
	}

	get lastModifiedUser (): string {
		return this._lastModifiedUser;
	}

	set lastModifiedUser (lastModifiedUser: string) {
		this._lastModifiedUser = lastModifiedUser;
	}
}
