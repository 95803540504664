export default class CcDiscount {
	private _description: string;
	private _amount: number;

	constructor (description: string, amount: number) {
		this._description = description;
		this._amount = amount;
	}

	get description (): string {
		return this._description;
	}

	set description (description: string) {
		this._description = description;
	}

	get amount (): number {
		return this._amount;
	}

	set amount (amount: number) {
		this._amount = amount;
	}
}
