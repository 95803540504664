import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'intersection-observer';
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import VueRouter from 'vue-router';
import { CC_CONTAINER_TYPES } from '../ioc/cc-container-types';
import messages from '../internationalization/messages';
import Router from '../router/router';
import Store from '../store/store';
import CcUser from '@/main/webapp/resources/src/vue/domain/user/cc-user';
import VCalendar from 'v-calendar';
import CcOrder from '@/main/webapp/resources/src/vue/domain/order/cc-order';
import { injectable, inject } from 'inversify';
import CcServiceInterface from '../../service/cc-service-interface';
import { CcApplicationInterface } from './cc-application-interface';
import { Utils } from '@/main/webapp/resources/src/vue/infrastructure/utils/helpers';
import Vue2TouchEvents from 'vue2-touch-events';
import * as Es6Promise from 'es6-promise';
import VueTippy, { TippyComponent } from 'vue-tippy';
import CcProvince from '@/main/webapp/resources/src/vue/domain/province/cc-province';

const CcApplicationComponent = () => import(/* webpackChunkName: 'cc-application' */ './../../components/cc-application.vue');

@injectable()
class CcApplication implements CcApplicationInterface {
	@inject(CC_CONTAINER_TYPES.CcServiceInterface)
	private service: CcServiceInterface;
	private configuration: any = {};

	static readonly APPLICATION_ROOT_NODE_SELECTOR: string = '#app';
	static readonly APPLICATION_DEFAULT_LOCALE: string = 'it';

	public run () {
		Es6Promise.polyfill();
		const applicationRootNode: HTMLDivElement = <HTMLDivElement>(document.querySelector(CcApplication.APPLICATION_ROOT_NODE_SELECTOR));
		if (applicationRootNode != null) {
			this.pluginsRegistration();
			this.internationalizationSetup();
			this.routerSetup();
			this.startApplication();
		}
	}

	private pluginsRegistration (): void {
		Vue.use(VueI18n);
		Vue.use(VueRouter);
		Vue.use(VCalendar, { componentPrefix: 'vc' });
		Vue.use(Vue2TouchEvents);
		Vue.use(VueTippy);
		Vue.component('tippy', TippyComponent);
	}

	private internationalizationSetup (): void {
		// @ts-ignore
		messages[CcApplication.APPLICATION_DEFAULT_LOCALE] = Object.assign({}, messages[CcApplication.APPLICATION_DEFAULT_LOCALE], messages[`${CcApplication.APPLICATION_DEFAULT_LOCALE}_${Utils.getAppBuildName()}`] || {});
		const i18n: VueI18n = new VueI18n({
			locale: CcApplication.APPLICATION_DEFAULT_LOCALE,
			messages: messages
		});
		Vue.$translator = i18n;
	}

	private routerSetup (): void {
		Router.beforeEach((to: any, from: any, next: any) => {
			let documentTitle: string = Vue.$translator.t(to.meta.title, {});
			if (from.name === null) {
				to.params.reload = 'true';
				to.params.reset = 'false';
			} else {
				to.params.reload = to.params.reload ? to.params.reload : to.query.reload ? to.query.reload : 'false';
				to.params.reset = to.params.reset ? to.params.reset : 'false';
			}
			switch (to.name) {
				case 'home':
					to.query.attribute = to.query.attribute ? to.query.attribute : Store.getters.searchAttribute.map((attribute: any) => attribute.code);
					to.query.descriptionLike = to.query.descriptionLike ? to.query.descriptionLike : Store.getters.searchDescriptionLike;
					to.query.categoryPaths = to.query.categoryPaths || Store.getters.searchCategoryPaths;
					to.query.customSort = to.query.customSort ? to.query.customSort : (Store.getters.searchCustomSort || {}).code;
					to.query.brands = to.query.brands ? to.query.brands : Store.getters.searchBrands.map((brand: any) => brand.code);
					break;
				case 'order':
					documentTitle = `${documentTitle} #${to.params.orderId}`;
					break;
				case 'order-confirm':
					documentTitle = Vue.$translator.t(to.meta.title, { 0: to.params.orderId });
					break;
				case 'products':
				case 'page':
					break;
				case 'error':
					documentTitle = `${documentTitle}${to.params.errorCode}`;
					break;
			}
			document.title = ((documentTitle) ? documentTitle.concat(' | ').concat(Utils.getAppSubtitle()) : '');
			let metaRobots: HTMLMetaElement = document.querySelector('head meta[name="robots"]');
			if (metaRobots) {
				metaRobots.content = to.meta.robots;
			} else {
				metaRobots = document.createElement('meta');
				metaRobots.setAttribute('name', 'robots');
				metaRobots.setAttribute('content', to.meta.robots);
				document.head.appendChild(metaRobots);
			}
			let metaDescription: HTMLMetaElement = document.querySelector('head meta[name="Description"]');
			let description: string = Vue.$translator.t(to.meta.description, {});
			if (metaDescription !== null) {
				if (to.meta.description === '') {
					document.querySelector('head').removeChild(document.querySelector('meta[name="Description"]'));
				} else {
					metaDescription.content = description;
				}
			} else {
				if (to.meta.description !== '') {
					metaDescription = document.createElement('meta');
					metaDescription.setAttribute('name', 'Description');
					metaDescription.setAttribute('content', description);
					document.head.appendChild(metaDescription);
				}
			}
			next();
		});
	}

	private startApplication (): void {
		let self: CcApplication = this;
		const VueApp: Vue = new Vue({
			el: '#app',
			store: Store,
			router: Router,
			i18n: Vue.$translator,
			provide: {
				[CC_CONTAINER_TYPES.CcServiceInterface]: this.service
			},
			async beforeCreate () {
				self.service.getUser().then((user: CcUser) => {
					Store.dispatch('setUser', user);
					if (user.logged) {
						self.loadOrder();
					} else {
						self.getProvinces();
						Store.dispatch('readyToStart', true);
					}
				});
			},
			render: (h: any) => h(CcApplicationComponent, {
				props: {
					accountEnabled: this.configuration.accountEnabled,
					passwordEnabled: this.configuration.passwordEnabled
				}
			})
		});
	}

	private loadOrder () {
		this.service.getCurrentOrder({}).then((orderData: CcOrder) => {
			Store.dispatch('setOrder', orderData);
			this.getProvinces();
		}).catch((error: Error) => {
			Utils.defaultApiErrorHandler(error);
		});
	}

	private getProvinces () {
		this.service.getProvinces().then((provincesList: Array<CcProvince>) => {
			Store.dispatch('setProvinces', provincesList);
			Store.dispatch('readyToStart', true);
		}).catch((error: any) => {
			Utils.defaultApiErrorHandler(error);
		});
	}
}

export { CcApplication };
