import StoreStateInterface from './store-state-interface';
import CcCustomer from '@/main/webapp/resources/src/vue/domain/user/cc-customer';

const storeState: StoreStateInterface = {
	appKey: 1,
	readyToStart: false,
	categories: [],
	products: [],
	productStocks: [],
	pageData: {
		number: 0,
		size: 0,
		totalPages: 0,
		totalElements: 0
	},
	user: null,
	order: null,
	orderNotes: '',
	orders: [],
	ordersPageData: {
		number: 0,
		size: 0,
		totalPages: 0,
		totalElements: 0
	},
	stores: [],
	searchParams: {
		categoryPaths: '',
		descriptionLike: '',
		attribute: [],
		brands: [],
		customSort: null,
		timestamp: 0,
		productTags: []
	},
	bannerAreaHome: null,
	bannerAreaHomePrivate: null,
	bannerAreaProducts: null,
	bannerAreaRegistration: null,
	searchAids: [],
	provinces: [],
	brands: [],
	isStoreOptionsShowed: false,
	urlPreLogin: '',
	disabledDates: [],
	customerShippingForm: {
		errors: [],
		shippingData: {}
	},
	secondaryCustomers: [],
	secondaryCustomersPageData: {
		number: 0,
		size: 0,
		totalPages: 0,
		totalElements: 0
	},
	productTags: [],
	collectTimeOptions: []
};
export default storeState;
