export default class CcProductTag {
	private _description: string;
	private _uniqueId: string;

	constructor (
		uniqueId: string,
		description: string
	) {
		this._description = description;
		this._uniqueId = uniqueId;
	}

	get uniqueId (): string {
		return this._uniqueId;
	}

	set uniqueId (uniqueId: string) {
		this._uniqueId = uniqueId;
	}

	get description (): string {
		return this._description;
	}

	set description (description: string) {
		this._description = description;
	}
}
