export default class CcBrand {
	private _code: string;
	private _name: string;
	private _position: number;

	constructor (code: string, name: string, position: number) {
		this._code = code;
		this._name = name;
		this._position = position;
	}

	get code (): string {
		return this._code;
	}

	set code (code: string) {
		this._code = code;
	}

	get name (): string {
		return this._name;
	}

	set name (name: string) {
		this._name = name;
	}

	get position (): number {
		return this._position;
	}

	set position (position: number) {
		this._position = position;
	}
}
