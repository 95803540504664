export default class CcPreparationCost {
	private _code: string;
	private _description: string;
	private _startPrice: number;
	private _endPrice: number;

	constructor (code: string, description: string, startPrice: number, endPrice: number) {
		this._code = code;
		this._description = description;
		this._endPrice = endPrice;
		this._startPrice = startPrice;
	}

	get code (): string {
		return this._code;
	}

	set code (code: string) {
		this._code = code;
	}

	get description (): string {
		return this._description;
	}

	set description (description: string) {
		this._description = description;
	}

	get startPrice (): number {
		return this._startPrice;
	}

	set startPrice (startPrice: number) {
		this._startPrice = startPrice;
	}

	get endPrice (): number {
		return this._endPrice;
	}

	set endPrice (endPrice: number) {
		this._endPrice = endPrice;
	}
}
