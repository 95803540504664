export default class CcDayHoursActivityRange {
	private _dayOfWeek: string;
	private _startTime: string;
	private _endTime: string;

	constructor (dayOfWeek: string, startTime: string, endTime: string) {
		this._dayOfWeek = dayOfWeek;
		this._startTime = startTime;
		this._endTime = endTime;
	}

	get dayOfWeek (): string {
		return this._dayOfWeek;
	}

	set dayOfWeek (dayOfWeek: string) {
		this._dayOfWeek = dayOfWeek;
	}

	get startTime (): string {
		return this._startTime;
	}

	set startTime (startTime: string) {
		this._startTime = startTime;
	}

	get endTime (): string {
		return this._endTime;
	}

	set endTime (endTime: string) {
		this._endTime = endTime;
	}
}
