export default class CcCategory {
	private _childCategoriesCount: number;
	private _code: string;
	private _creationDate: string;
	private _creationUser: string;
	private _ecrCategoriesCount: number;
	private _leaf: boolean;
	private _level: number;
	private _name: string;
	private _path: string;
	private _root: boolean;
	private _unit: string = null;
	private _lastModifiedDate: string = null;
	private _lastModifiedUser: string = null;
	private _customCategory: CcCategory = null;
	private _children: Array<CcCategory> = [];

	constructor (
		childCategoriesCount: number,
		code: string,
		creationDate: string,
		creationUser: string,
		ecrCategoriesCount: number,
		leaf: boolean,
		level: number,
		name: string,
		path: string,
		root: boolean
	) {
		this._childCategoriesCount = childCategoriesCount;
		this._code = code;
		this._creationDate = creationDate;
		this._creationUser = creationUser;
		this._ecrCategoriesCount = ecrCategoriesCount;
		this._leaf = leaf;
		this._level = level;
		this._name = name;
		this._path = path;
		this._root = root;
	}

	get childCategoriesCount (): number {
		return this._childCategoriesCount;
	}

	set childCategoriesCount (childCategoriesCount: number) {
		this._childCategoriesCount = childCategoriesCount;
	}

	get code (): string {
		return this._code;
	}

	set code (code: string) {
		this._code = code;
	}

	get creationDate (): string {
		return this._creationDate;
	}

	set creationDate (creationDate: string) {
		this._creationDate = creationDate;
	}

	get creationUser (): string {
		return this._creationUser;
	}

	set creationUser (creationUser: string) {
		this._creationUser = creationUser;
	}

	get ecrCategoriesCount (): number {
		return this._ecrCategoriesCount;
	}

	set ecrCategoriesCount (ecrCategoriesCount: number) {
		this._ecrCategoriesCount = ecrCategoriesCount;
	}

	get lastModifiedDate (): string {
		return this._lastModifiedDate;
	}

	set lastModifiedDate (lastModifiedDate: string) {
		this._lastModifiedDate = lastModifiedDate;
	}

	get lastModifiedUser (): string {
		return this._lastModifiedUser;
	}

	set lastModifiedUser (lastModifiedUser: string) {
		this._lastModifiedUser = lastModifiedUser;
	}

	get leaf (): boolean {
		return this._leaf;
	}

	set leaf (leaf: boolean) {
		this._leaf = leaf;
	}

	get level (): number {
		return this._level;
	}

	set level (level: number) {
		this._level = level;
	}

	get name (): string {
		return this._name;
	}

	set name (name: string) {
		this._name = name;
	}

	get path (): string {
		return this._path;
	}

	set path (path: string) {
		this._path = path;
	}

	get root (): boolean {
		return this._root;
	}

	set root (root: boolean) {
		this._root = root;
	}

	get unit (): string {
		return this._unit;
	}

	set unit (unit: string) {
		this._unit = unit;
	}

	get customCategory (): CcCategory {
		return this._customCategory;
	}

	set customCategory (customCategory: CcCategory) {
		this._customCategory = customCategory;
	}

	get children (): Array<CcCategory> {
		return this._children;
	}

	set children (children: Array<CcCategory>) {
		this._children = children;
	}
}
