import * as Qs from 'qs';
import Swal from 'sweetalert2';
import Router from '../router/router';
import { RouterMode } from 'vue-router';
import { CC_CONTAINER_TYPES } from '@/main/webapp/resources/src/vue/infrastructure/ioc/cc-container-types';
import { inject } from 'inversify';
import CcServiceInterface from '../../service/cc-service-interface';
import CcService from '@/main/webapp/resources/src/vue/service/cc-service';
import CcCustomer from '@/main/webapp/resources/src/vue/domain/user/cc-customer';
import CcProvince from '@/main/webapp/resources/src/vue/domain/province/cc-province';

class Utils {
	static readonly BEARER_ACCESS_TOKEN_KEY: string = 'cc_shop_bearer_access_token';
	static readonly BEARER_REFRESH_TOKEN_KEY: string = 'cc_shop_bearer_refresh_token';

	static readonly ORDER_NOTES: string = 'cc_shop_order_notes';
	static readonly ORDER_OPTIONS_MODAL_ID: string = 'ui-modal.cc-page-home.order-options';

	static readonly customComponentsConfig: any = {
		'cc-application-navigation-menu': {
			default: import(/* webpackChunkName: 'cc-application-navigation-menu' */ '@/main/webapp/resources/src/vue/components/cc-application-navigation-menu/cc-application-navigation-menu.vue'),
			migross: import(/* webpackChunkName: 'cc-application-navigation-menu' */ '@/main/webapp/resources/src/vue/components/overrides/migross/cc-application-navigation-menu/cc-application-navigation-menu.vue')
		},
		'cc-cart-item': {
			default: import(/* webpackChunkName: 'cc-cart-item' */ '@/main/webapp/resources/src/vue/components/cc-cart-item/cc-cart-item.vue'),
			migross: import(/* webpackChunkName: 'cc-cart-item' */ '@/main/webapp/resources/src/vue/components/overrides/migross/cc-cart-item/cc-cart-item.vue')
		},
		'cc-footer': {
			default: import(/* webpackChunkName: 'cc-footer' */ '@/main/webapp/resources/src/vue/components/cc-footer/cc-footer.vue'),
			migross: import(/* webpackChunkName: 'cc-footer' */ '@/main/webapp/resources/src/vue/components/overrides/migross/cc-footer/cc-footer.vue')
		},
		'cc-header': {
			default: import(/* webpackChunkName: 'cc-header' */ '@/main/webapp/resources/src/vue/components/cc-header/cc-header.vue'),
			migross: import(/* webpackChunkName: 'cc-header' */ '@/main/webapp/resources/src/vue/components/overrides/migross/cc-header/cc-header.vue')
		},
		'cc-page-home': {
			default: import(/* webpackChunkName: 'cc-page-home' */ '@/main/webapp/resources/src/vue/components/cc-page-home/cc-page-home.vue'),
			migross: import(/* webpackChunkName: 'cc-page-home' */ '@/main/webapp/resources/src/vue/components/overrides/migross/cc-page-home/cc-page-home.vue')
		},
		'cc-product-stock': {
			default: import(/* webpackChunkName: 'cc-product-stock' */ '@/main/webapp/resources/src/vue/components/cc-product-stock/cc-product-stock.vue'),
			migross: import(/* webpackChunkName: 'cc-product-stock' */ '@/main/webapp/resources/src/vue/components/overrides/migross/cc-product-stock/cc-product-stock.vue')
		},
		'cc-product-stock-informations': {
			default: import(/* webpackChunkName: 'cc-product-stock-informations' */ '@/main/webapp/resources/src/vue/components/cc-product-stock-informations/cc-product-stock-informations.vue'),
			migross: import(/* webpackChunkName: 'cc-product-stock-informations' */ '@/main/webapp/resources/src/vue/components/overrides/migross/cc-product-stock-informations/cc-product-stock-informations.vue')
		}
	}

	private static readonly XMLHttpRequestSetup = {
		paramsSerializer: function (params: object) {
			return Qs.stringify(params, {
				arrayFormat: 'repeat'
			});
		}
	};

	public static getCustomComponent (componentName: string): any {
		const customer: string = this.getAppBuildName();
		const components: any = this.customComponentsConfig[componentName];

		return components[customer] ? () => components[customer] : () => components['default'];
	}

	public static buildRequestPayload (data: object) {
		return Object.assign(data, Utils.XMLHttpRequestSetup);
	}

	public static getBasePath (): string {
		const baseElements: HTMLCollectionOf<HTMLBaseElement> = document.getElementsByTagName('base');
		return baseElements.length > 0 ? baseElements[0].href : '';
	}

	public static getContextPath () {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_context_path')[0]
		);
		return meta ? meta.content : '';
	}

	public static getAppBuildName () {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_build_name')[0]
		);
		return meta ? meta.content : '';
	}

	public static getAppSubtitle () {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_app_subtitle')[0]
		);
		return meta ? meta.content : 'Click&Shop';
	}

	public static getRegistrationUrl () {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_registration_url')[0]
		);
		return meta ? meta.content : null;
	}

	public static getProfileUpdateUrl () {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_profile_update_url')[0]
		);
		return meta ? meta.content : null;
	}

	public static getChangePasswordUrl () {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_change_password_url')[0]
		);
		return meta ? meta.content : null;
	}

	public static isFilterCategoriesEnabled () {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_filter_categories_status')[0]
		);
		return meta ? meta.content !== 'disabled' : true;
	}

	public static isFilterBrandsEnabled () {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_filter_brands_status')[0]
		);
		return meta ? meta.content !== 'disabled' : true;
	}

	public static isFilterTagsEnabled () {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_filter_tags_status')[0]
		);
		return meta ? meta.content !== 'disabled' : true;
	}

	public static isFilterTypesEnabled () {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_filter_types_status')[0]
		);
		return meta ? meta.content !== 'disabled' : true;
	}

	public static getForgotPasswordUrl () {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_forgot_password_url')[0]
		);
		return meta ? meta.content : null;
	}

	public static getOpenUrlTarget () {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('custom_address')[0]
		);
		return meta ? meta.content : '_self';
	}

	public static isCustomAddressEnabled () {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_custom_address_enabled')[0]
		);
		return meta ? meta.content === 'true' : false;
	}

	public static getRegistrationCustomerRequired (): boolean {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_registration_customer_required')[0]
		);
		return meta ? meta.content === 'true' : true;
	}

	public static registrationUseOnlyVatnumber (): boolean {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_registration_only_vatnumber')[0]
		);
		return meta ? meta.content === 'true' : false;
	}

	public static routingMode (): RouterMode {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
		document.getElementsByName('_routing_mode')[0]
		);
		return meta ? <RouterMode>meta.content : 'history';
	}

	public static getCsrfCode () {
		const meta: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_csrf')[0]
		);
		return meta ? meta.content : null;
	}

	public static getLanguageCode () {
		return document.documentElement !== null
			? document.documentElement.lang
			: '';
	}

	public static getPermalik (partial: string): string {
		return (Utils.getApiPath() !== '') ? Utils.getApiPath() + partial : Utils.getContextPath() + partial;
	}

	public static getApiPath (): string {
		const apiPath: HTMLMetaElement = <HTMLMetaElement>(
		document.getElementsByName('_api_path')[0]);
		return apiPath ? apiPath.content : '';
	}

	public static isArrayEquals (
		arrayOne: Array<any>,
		arrayTwo: Array<any>
	): boolean {
		if (!arrayOne || !arrayTwo) {
			return false;
		}

		if (arrayOne.length !== arrayTwo.length) {
			return false;
		}

		for (var i = 0, l = arrayOne.length; i < l; i++) {
			if (arrayOne[i] instanceof Array && arrayTwo[i] instanceof Array) {
				if (!this.isArrayEquals(arrayOne[i], arrayTwo[i])) {
					return false;
				}
			} else if (arrayOne[i] !== arrayTwo[i]) {
				return false;
			}
		}
		return true;
	}

	public static downloadFile (
		downloadData: ArrayBuffer,
		filename: string,
		contentType: string
	) {
		const data = new Blob([downloadData], { type: contentType });
		const link = document.createElement('a');
		document.body.appendChild(link);
		link.href = window.URL.createObjectURL(data);
		link.download = filename;
		link.click();
		window.URL.revokeObjectURL(link.href);
		link.remove();
	}

	public static setWorking (working: boolean, element?: HTMLElement) {
		if (element != null) {
			(working ? element.classList.add('ui-working') : element.classList.remove('ui-working'));
		} else {
			let bodyElement = <HTMLElement>document.documentElement.querySelector('body');
			(working ? bodyElement.classList.add('ui-working') : bodyElement.classList.remove('ui-working'));
		}
	}

	public static setApplicationScrollable (scrollable: boolean) {
		let bodyElement = <HTMLElement>document.documentElement.querySelector('body');
		(scrollable ? bodyElement.classList.remove('stop-scrolling-from-modal') : bodyElement.classList.add('stop-scrolling-from-modal'));
	}

	public static setContentSelectable (selectable: boolean, element?: HTMLElement) {
		if (element != null) {
			(selectable ? element.classList.remove('user-select-disabled') : element.classList.add('user-select-disabled'));
		} else {
			let bodyElement = <HTMLElement>document.documentElement.querySelector('body');
			(selectable ? bodyElement.classList.remove('user-select-disabled') : bodyElement.classList.add('user-select-disabled'));
		}
	}

	public static unstringify (value: any, sep: any, eq: any) {
		if (typeof value === 'string') {
			const properties = decodeURIComponent(value).split(sep);
			const arr = [];
			for (var i = 0; i < properties.length; i++) {
				const prop = properties[i].split(eq);
				const obj: any = {};
				obj[prop[0]] = prop[1];
				arr.push(obj);
			}
			return arr;
		} else {
			return value;
		}
	}

	public static dateFromTimestamp (timestamp: string, time: boolean): Date {
		let output: Date = new Date();
		if (timestamp) {
			const year: number = parseInt(timestamp.substr(0, 4));
			const month: number = parseInt(timestamp.substr(4, 2)) - 1;
			const day: number = parseInt(timestamp.substr(6, 2));
			const hours: number = parseInt(timestamp.substr(8, 2));
			const minutes: number = parseInt(timestamp.substr(10, 2));
			const seconds: number = parseInt(timestamp.substr(12, 2));

			output = time
				? new Date(year, month, day, hours, minutes, seconds)
				: new Date(year, month, day);
		}
		return output;
	}

	public static sendToAutenticationPage () {
		if (this.routingMode() === 'history') {
			window.location.assign(this.getPermalik('/login'));
		} else {
			window.location.assign(this.getPermalik('/#/login'));
		}
	}

	public static defaultApiErrorHandler (error: any) {
		switch (error.response.status) {
			case 401:
				if (Utils.localStorageGetItem(Utils.BEARER_ACCESS_TOKEN_KEY) && Utils.localStorageGetItem(Utils.BEARER_REFRESH_TOKEN_KEY)) {
					Utils.localStorageRemoveItem(Utils.BEARER_ACCESS_TOKEN_KEY);
					inject(CC_CONTAINER_TYPES.CcServiceInterface);
					const service: CcServiceInterface = new CcService();
					service.refreshToken()
						.then(() => {
							window.location.reload();
						})
						.catch(() => {
							Utils.localStorageRemoveItem(Utils.BEARER_REFRESH_TOKEN_KEY);
							Utils.sendToAutenticationPage();
						});
				} else if (Utils.localStorageGetItem(Utils.BEARER_REFRESH_TOKEN_KEY) === null) {
					Utils.localStorageRemoveItem(Utils.BEARER_ACCESS_TOKEN_KEY);
					Utils.sendToAutenticationPage();
				}
				break;
			case 400:
			case 403:
			case 404:
				Router.push({ name: 'error', params: { errorCode: error.response.status } });
				break;
			default:
				Swal.fire({
					title: '',
					html: `Si è verificato un errore ${error.response.status}.`,
					type: 'error',
					buttonsStyling: false,
					confirmButtonClass: 'ui-button primary round'
				});
		}
	}

	public static localStorageSetItem (key: string, value: string) {
		localStorage.setItem(this.getContextPath().replace('/', '').concat(`.${key}`), value);
	}

	public static localStorageGetItem (key: string): string {
		return localStorage.getItem(this.getContextPath().replace('/', '').concat(`.${key}`));
	}

	public static localStorageRemoveItem (key: string) {
		localStorage.removeItem(this.getContextPath().replace('/', '').concat(`.${key}`));
	}

	public static getLocaleDateString (hours: boolean) {
		const formats: any = {
			'ar-SA': 'dd/MM/yy',
			'bg-BG': 'dd.M.yyyy',
			'ca-ES': 'dd/MM/yyyy',
			'zh-TW': 'yyyy/M/d',
			'cs-CZ': 'd.M.yyyy',
			'da-DK': 'dd-MM-yyyy',
			'de-DE': 'dd.MM.yyyy',
			'el-GR': 'd/M/yyyy',
			'en-US': 'M/d/yyyy',
			'fi-FI': 'd.M.yyyy',
			'fr-FR': 'dd/MM/yyyy',
			'he-IL': 'dd/MM/yyyy',
			'hu-HU': 'yyyy. MM. dd.',
			'is-IS': 'd.M.yyyy',
			'it-IT': 'dd/MM/yyyy',
			'ja-JP': 'yyyy/MM/dd',
			'ko-KR': 'yyyy-MM-dd',
			'nl-NL': 'd-M-yyyy',
			'nb-NO': 'dd.MM.yyyy',
			'pl-PL': 'yyyy-MM-dd',
			'pt-BR': 'd/M/yyyy',
			'ro-RO': 'dd.MM.yyyy',
			'ru-RU': 'dd.MM.yyyy',
			'hr-HR': 'd.M.yyyy',
			'sk-SK': 'd. M. yyyy',
			'sq-AL': 'yyyy-MM-dd',
			'sv-SE': 'yyyy-MM-dd',
			'th-TH': 'd/M/yyyy',
			'tr-TR': 'dd.MM.yyyy',
			'ur-PK': 'dd/MM/yyyy',
			'id-ID': 'dd/MM/yyyy',
			'uk-UA': 'dd.MM.yyyy',
			'be-BY': 'dd.MM.yyyy',
			'sl-SI': 'd.M.yyyy',
			'et-EE': 'd.MM.yyyy',
			'lv-LV': 'yyyy.MM.dd.',
			'lt-LT': 'yyyy.MM.dd',
			'fa-IR': 'MM/dd/yyyy',
			'vi-VN': 'dd/MM/yyyy',
			'hy-AM': 'dd.MM.yyyy',
			'az-Latn-AZ': 'dd.MM.yyyy',
			'eu-ES': 'yyyy/MM/dd',
			'mk-MK': 'dd.MM.yyyy',
			'af-ZA': 'yyyy/MM/dd',
			'ka-GE': 'dd.MM.yyyy',
			'fo-FO': 'dd-MM-yyyy',
			'hi-IN': 'dd-MM-yyyy',
			'ms-MY': 'dd/MM/yyyy',
			'kk-KZ': 'dd.MM.yyyy',
			'ky-KG': 'dd.MM.yy',
			'sw-KE': 'M/d/yyyy',
			'uz-Latn-UZ': 'dd/MM yyyy',
			'tt-RU': 'dd.MM.yyyy',
			'pa-IN': 'dd-MM-yy',
			'gu-IN': 'dd-MM-yy',
			'ta-IN': 'dd-MM-yyyy',
			'te-IN': 'dd-MM-yy',
			'kn-IN': 'dd-MM-yy',
			'mr-IN': 'dd-MM-yyyy',
			'sa-IN': 'dd-MM-yyyy',
			'mn-MN': 'yy.MM.dd',
			'gl-ES': 'dd/MM/yy',
			'kok-IN': 'dd-MM-yyyy',
			'syr-SY': 'dd/MM/yyyy',
			'dv-MV': 'dd/MM/yy',
			'ar-IQ': 'dd/MM/yyyy',
			'zh-CN': 'yyyy/M/d',
			'de-CH': 'dd.MM.yyyy',
			'en-GB': 'dd/MM/yyyy',
			'es-MX': 'dd/MM/yyyy',
			'fr-BE': 'd/MM/yyyy',
			'it-CH': 'dd.MM.yyyy',
			'nl-BE': 'd/MM/yyyy',
			'nn-NO': 'dd.MM.yyyy',
			'pt-PT': 'dd-MM-yyyy',
			'sr-Latn-CS': 'd.M.yyyy',
			'sv-FI': 'd.M.yyyy',
			'az-Cyrl-AZ': 'dd.MM.yyyy',
			'ms-BN': 'dd/MM/yyyy',
			'uz-Cyrl-UZ': 'dd.MM.yyyy',
			'ar-EG': 'dd/MM/yyyy',
			'zh-HK': 'd/M/yyyy',
			'de-AT': 'dd.MM.yyyy',
			'en-AU': 'd/MM/yyyy',
			'es-ES': 'dd/MM/yyyy',
			'fr-CA': 'yyyy-MM-dd',
			'sr-Cyrl-CS': 'd.M.yyyy',
			'ar-LY': 'dd/MM/yyyy',
			'zh-SG': 'd/M/yyyy',
			'de-LU': 'dd.MM.yyyy',
			'en-CA': 'dd/MM/yyyy',
			'es-GT': 'dd/MM/yyyy',
			'fr-CH': 'dd.MM.yyyy',
			'ar-DZ': 'dd-MM-yyyy',
			'zh-MO': 'd/M/yyyy',
			'de-LI': 'dd.MM.yyyy',
			'en-NZ': 'd/MM/yyyy',
			'es-CR': 'dd/MM/yyyy',
			'fr-LU': 'dd/MM/yyyy',
			'ar-MA': 'dd-MM-yyyy',
			'en-IE': 'dd/MM/yyyy',
			'es-PA': 'MM/dd/yyyy',
			'fr-MC': 'dd/MM/yyyy',
			'ar-TN': 'dd-MM-yyyy',
			'en-ZA': 'yyyy/MM/dd',
			'es-DO': 'dd/MM/yyyy',
			'ar-OM': 'dd/MM/yyyy',
			'en-JM': 'dd/MM/yyyy',
			'es-VE': 'dd/MM/yyyy',
			'ar-YE': 'dd/MM/yyyy',
			'en-029': 'MM/dd/yyyy',
			'es-CO': 'dd/MM/yyyy',
			'ar-SY': 'dd/MM/yyyy',
			'en-BZ': 'dd/MM/yyyy',
			'es-PE': 'dd/MM/yyyy',
			'ar-JO': 'dd/MM/yyyy',
			'en-TT': 'dd/MM/yyyy',
			'es-AR': 'dd/MM/yyyy',
			'ar-LB': 'dd/MM/yyyy',
			'en-ZW': 'M/d/yyyy',
			'es-EC': 'dd/MM/yyyy',
			'ar-KW': 'dd/MM/yyyy',
			'en-PH': 'M/d/yyyy',
			'es-CL': 'dd-MM-yyyy',
			'ar-AE': 'dd/MM/yyyy',
			'es-UY': 'dd/MM/yyyy',
			'ar-BH': 'dd/MM/yyyy',
			'es-PY': 'dd/MM/yyyy',
			'ar-QA': 'dd/MM/yyyy',
			'es-BO': 'dd/MM/yyyy',
			'es-SV': 'dd/MM/yyyy',
			'es-HN': 'dd/MM/yyyy',
			'es-NI': 'dd/MM/yyyy',
			'es-PR': 'dd/MM/yyyy',
			'am-ET': 'd/M/yyyy',
			'tzm-Latn-DZ': 'dd-MM-yyyy',
			'iu-Latn-CA': 'd/MM/yyyy',
			'sma-NO': 'dd.MM.yyyy',
			'mn-Mong-CN': 'yyyy/M/d',
			'gd-GB': 'dd/MM/yyyy',
			'en-MY': 'd/M/yyyy',
			'prs-AF': 'dd/MM/yy',
			'bn-BD': 'dd-MM-yy',
			'wo-SN': 'dd/MM/yyyy',
			'rw-RW': 'M/d/yyyy',
			'qut-GT': 'dd/MM/yyyy',
			'sah-RU': 'MM.dd.yyyy',
			'gsw-FR': 'dd/MM/yyyy',
			'co-FR': 'dd/MM/yyyy',
			'oc-FR': 'dd/MM/yyyy',
			'mi-NZ': 'dd/MM/yyyy',
			'ga-IE': 'dd/MM/yyyy',
			'se-SE': 'yyyy-MM-dd',
			'br-FR': 'dd/MM/yyyy',
			'smn-FI': 'd.M.yyyy',
			'moh-CA': 'M/d/yyyy',
			'arn-CL': 'dd-MM-yyyy',
			'ii-CN': 'yyyy/M/d',
			'dsb-DE': 'd. M. yyyy',
			'ig-NG': 'd/M/yyyy',
			'kl-GL': 'dd-MM-yyyy',
			'lb-LU': 'dd/MM/yyyy',
			'ba-RU': 'dd.MM.yy',
			'nso-ZA': 'yyyy/MM/dd',
			'quz-BO': 'dd/MM/yyyy',
			'yo-NG': 'd/M/yyyy',
			'ha-Latn-NG': 'd/M/yyyy',
			'fil-PH': 'M/d/yyyy',
			'ps-AF': 'dd/MM/yy',
			'fy-NL': 'd-M-yyyy',
			'ne-NP': 'M/d/yyyy',
			'se-NO': 'dd.MM.yyyy',
			'iu-Cans-CA': 'd/M/yyyy',
			'sr-Latn-RS': 'd.M.yyyy',
			'si-LK': 'yyyy-MM-dd',
			'sr-Cyrl-RS': 'd.M.yyyy',
			'lo-LA': 'dd/MM/yyyy',
			'km-KH': 'yyyy-MM-dd',
			'cy-GB': 'dd/MM/yyyy',
			'bo-CN': 'yyyy/M/d',
			'sms-FI': 'd.M.yyyy',
			'as-IN': 'dd-MM-yyyy',
			'ml-IN': 'dd-MM-yy',
			'en-IN': 'dd-MM-yyyy',
			'or-IN': 'dd-MM-yy',
			'bn-IN': 'dd-MM-yy',
			'tk-TM': 'dd.MM.yy',
			'bs-Latn-BA': 'd.M.yyyy',
			'mt-MT': 'dd/MM/yyyy',
			'sr-Cyrl-ME': 'd.M.yyyy',
			'se-FI': 'd.M.yyyy',
			'zu-ZA': 'yyyy/MM/dd',
			'xh-ZA': 'yyyy/MM/dd',
			'tn-ZA': 'yyyy/MM/dd',
			'hsb-DE': 'd. M. yyyy',
			'bs-Cyrl-BA': 'd.M.yyyy',
			'tg-Cyrl-TJ': 'dd.MM.yy',
			'sr-Latn-BA': 'd.M.yyyy',
			'smj-NO': 'dd.MM.yyyy',
			'rm-CH': 'dd/MM/yyyy',
			'smj-SE': 'yyyy-MM-dd',
			'quz-EC': 'dd/MM/yyyy',
			'quz-PE': 'dd/MM/yyyy',
			'hr-BA': 'd.M.yyyy.',
			'sr-Latn-ME': 'd.M.yyyy',
			'sma-SE': 'yyyy-MM-dd',
			'en-SG': 'd/M/yyyy',
			'ug-CN': 'yyyy-M-d',
			'sr-Cyrl-BA': 'd.M.yyyy',
			'es-US': 'M/d/yyyy'
		};

		const dateFormat: string = formats[navigator.language] || 'dd/MM/yyyy';
		return hours ? dateFormat.toUpperCase().concat(' HH:mm') : dateFormat.toUpperCase();
	}

	public static setAnaliticsTraker (params: any) {
		if (Utils.isAnaliticsTrakerEnabled()) {
			(<any>window).dataLayer = (<any>window).dataLayer || [];
			(<any>window).dataLayer.push(params);
		}
	}

	public static isAnaliticsTrakerEnabled (): boolean {
		const isAnaliticsTrakerEnabled: HTMLMetaElement = <HTMLMetaElement>document.getElementsByName('_analitics_traker_enabled')[0] || null;
		return isAnaliticsTrakerEnabled ? isAnaliticsTrakerEnabled.content === 'true' : false;
	}

	public static clean (object: any): any {
		let result: any = {};
		Object.entries(object).forEach((param: Array<any>) => {
			const isNotEmpty: boolean = Array.isArray(param[1]) ? param[1].length > 0 : !!param[1] || param[1] === 0 || param[1] === false;

			if (isNotEmpty) {
				result[param[0]] = param[1];
			}
		});
		return result;
	}
}

export { Utils };
