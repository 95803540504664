export default class CcNutritionnumeric {
	private _headings: Array<string> = [];
	private _nutrients: Array<string> = [];
	private _amounts: Array<any> = [];

	constructor (headings: Array<string>, nutrients: Array<string>, amounts: Array<any>) {
		this._headings = headings;
		this._nutrients = nutrients;
		this._amounts = amounts;
	}

	get headings (): Array<string> {
		return this._headings;
	}

	set headings (headings: Array<string>) {
		this._headings = headings;
	}

	get nutrients (): Array<string> {
		return this._nutrients;
	}

	set nutrients (nutrients: Array<string>) {
		this._nutrients = nutrients;
	}

	get amounts (): Array<any> {
		return this._amounts;
	}

	set amounts (amounts: Array<any>) {
		this._amounts = amounts;
	}
}
