import CcImage from '@/main/webapp/resources/src/vue/domain/image/cc-image';

export default class CcBanner {
	private _alias: string;
	private _image: CcImage;
	private _name: string;
	private _published: boolean;
	private _title: string;
	private _url: string;
	private _target: string;
	private _clicks: number;
	private _creationDate: string;
	private _creationUser: string;
	private _impressions: number;
	private _lastModifiedDate: string;
	private _lastModifiedUser: string;
	private _notes: string = null;
	private _priority: number;

	constructor (name: string, alias: string, title: string, url: string, image: CcImage, published: boolean, target: string) {
		this._alias = alias;
		this._name = name;
		this._title = title;
		this._url = url;
		this._image = image;
		this._published = published;
		this._target = target;
	}

	get alias (): string {
		return this._alias;
	}

	set alias (alias: string) {
		this._alias = alias;
	}

	get name (): string {
		return this._name;
	}

	set name (name: string) {
		this._name = name;
	}

	get title (): string {
		return this._title;
	}

	set title (title: string) {
		this._title = title;
	}

	get url (): string {
		return this._url;
	}

	set url (url: string) {
		this._url = url;
	}

	get image (): CcImage {
		return this._image;
	}

	set image (image: CcImage) {
		this._image = image;
	}

	get published (): boolean {
		return this._published;
	}

	set published (published:boolean) {
		this._published = published;
	}

	get target (): string {
		return this._target;
	}

	set target (target: string) {
		this._target = target;
	}
}
