import CcAccountstatus from '@/main/webapp/resources/src/vue/domain/user/cc-accountstatus';
import CcGender from '@/main/webapp/resources/src/vue/domain/user/cc-gender';
import CcProvince from '@/main/webapp/resources/src/vue/domain/province/cc-province';
import CcStore from '@/main/webapp/resources/src/vue/domain/store/cc-store';
import CcProperty from '@/main/webapp/resources/src/vue/domain/user/cc-property';
import CcCustomer from '@/main/webapp/resources/src/vue/domain/user/cc-customer';

export default class CcUser {
	private _logged: boolean;
	private _accountStatus: CcAccountstatus = null;
	private _firstName: string = null;
	private _lastName: string = null;
	private _accountExpired: boolean = null;
	private _address: string = null;
	private _birthdate: string = null;
	private _taxCode: string = null;
	private _gender: CcGender = null;
	private _city: string = null;
	private _postalCode: string = null;
	private _mobile: string = null;
	private _phone: string = null;
	private _fax: string = null;
	private _email: string = null;
	private _province: CcProvince = null;
	private _routeStore: CcStore = null;
	private _deliveryMinimumOrderAmount: number = 0;
	private _properties: Array<CcProperty> = [];
	private _customer: CcCustomer = null;
	private _currentCustomer: CcCustomer = null;

	constructor (logged: boolean) {
		this._logged = logged;
	}

	get logged (): boolean {
		return this._logged;
	}

	set logged (logged: boolean) {
		this._logged = logged;
	}

	get accountExpired (): boolean {
		return this._accountExpired;
	}

	set accountExpired (accountExpired: boolean) {
		this._accountExpired = accountExpired;
	}

	get accountStatus (): CcAccountstatus {
		return this._accountStatus;
	}

	set accountStatus (accountStatus: CcAccountstatus) {
		this._accountStatus = accountStatus;
	}

	get firstName (): string {
		return this._firstName;
	}

	set firstName (firstName: string) {
		this._firstName = firstName;
	}

	get lastName (): string {
		return this._lastName;
	}

	set lastName (lastName: string) {
		this._lastName = lastName;
	}

	get address (): string {
		return this._address;
	}

	set address (address: string) {
		this._address = address;
	}

	get birthdate (): string {
		return this._birthdate;
	}

	set birthdate (birthdate: string) {
		this._birthdate = birthdate;
	}

	get taxCode (): string {
		return this._taxCode;
	}

	set taxCode (taxCode: string) {
		this._taxCode = taxCode;
	}

	get gender (): CcGender {
		return this._gender;
	}

	set gender (gender: CcGender) {
		this._gender = gender;
	}

	get city (): string {
		return this._city;
	}

	set city (city: string) {
		this._city = city;
	}

	get postalCode (): string {
		return this._postalCode;
	}

	set postalCode (postalCode: string) {
		this._postalCode = postalCode;
	}

	get phone (): string {
		return this._phone;
	}

	set phone (phone: string) {
		this._phone = phone;
	}

	get mobile (): string {
		return this._mobile;
	}

	set mobile (mobile: string) {
		this._mobile = mobile;
	}

	get fax (): string {
		return this._fax;
	}

	set fax (fax: string) {
		this._fax = fax;
	}

	get email (): string {
		return this._email;
	}

	set email (email: string) {
		this._email = email;
	}

	get province (): CcProvince {
		return this._province;
	}

	set province (province: CcProvince) {
		this._province = province;
	}

	get routeStore (): CcStore {
		return this._routeStore;
	}

	set routeStore (routeStore: CcStore) {
		this._routeStore = routeStore;
	}

	get deliveryMinimumOrderAmount (): number {
		return this._deliveryMinimumOrderAmount;
	}

	set deliveryMinimumOrderAmount (deliveryMinimumOrderAmount: number) {
		this._deliveryMinimumOrderAmount = deliveryMinimumOrderAmount;
	}

	get properties (): Array<CcProperty> {
		return this._properties;
	}

	set properties (properties: Array<CcProperty>) {
		this._properties = properties;
	}

	get customer (): CcCustomer {
		return this._customer;
	}

	set customer (customer: CcCustomer) {
		this._customer = customer;
	}

	get currentCustomer (): CcCustomer {
		return this._currentCustomer;
	}

	set currentCustomer (currentCustomer: CcCustomer) {
		this._currentCustomer = currentCustomer;
	}
}
