import { injectable } from 'inversify';
import { ApiInterface } from './interfaces/api-interface';
import Http from 'axios';
import * as qs from 'qs';
import { CcProductAttribute } from '@/main/webapp/resources/src/vue/domain/product/cc-productattribute';
import CcBannerAreaCode from '@/main/webapp/resources/src/vue/domain/banner/cc-banner-area-code';
import CcBrand from '@/main/webapp/resources/src/vue/domain/brand/cc-brand';

@injectable()
class Api implements ApiInterface {
	private static readonly BASE_API_PATH: string = 'api';

	constructor () {
		this.setDefaultHeaders();
	}

	public getBannerArea (code: CcBannerAreaCode): Promise<any> {
		return new Promise((resolve, reject) => {
			Http.get(this.build('banner-area'), {
				params: {
					code: code
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getCustomCategory (
		path: string,
		nameLike: string,
		noParentCategory: boolean,
		parentCategoryPath: string,
		nameOrAnchestorCategoryNameLike: string,
		customCategoryFilled: boolean
	): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('custom-categories'), {
				params: {
					path: path,
					nameLike: nameLike,
					noParentCategory: noParentCategory,
					parentCategoryPath: parentCategoryPath,
					nameOrAnchestorCategoryNameLike: nameOrAnchestorCategoryNameLike,
					customCategoryFilled: customCategoryFilled
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getProducts (
		brandCodes: Array<CcBrand>,
		customerDescriptionLike: string,
		customCategoryPaths: string,
		attributes: Array<CcProductAttribute>,
		size: number,
		page: number
	): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('products'), {
				params: {
					brandCodes: brandCodes,
					customerDescriptionLike: customerDescriptionLike,
					customCategoryPaths: customCategoryPaths,
					attributes: attributes,
					page: page,
					size: size
				},
				paramsSerializer: params => {
					return qs.stringify(params, {
						arrayFormat: 'repeat',
						skipNulls: false
					});
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public setProductQuantity (productCode: string, quantity: number): any {
		return new Promise((resolve, reject) => {
			Http.get(
				this.build('private/current-order/set-product-quantity'),
				{
					params: {
						productCode: productCode,
						quantity: quantity
					}
				}
			)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getStores (
		codeLike: string,
		nameLike: string,
		status: string,
		vatNumberLike: string
	): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('stores'), {
				params: {
					codeLike: codeLike,
					nameLike: nameLike,
					status: status,
					vatNumberLike: vatNumberLike
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getPage (alias: string): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('page'), {
				params: {
					alias: alias
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getProductStocks (
		onSale: boolean,
		productAttributes: Array<CcProductAttribute>,
		productBrandCodes: Array<CcBrand>,
		productTagIds: Array<string>,
		productCustomerDescriptionLike: string,
		productCustomCategoryPaths: string,
		customSort: string,
		size: number,
		page: number
	): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('private/product-stocks'), {
				params: {
					onSale: onSale,
					productAttributes: productAttributes,
					productBrandCodes: productBrandCodes,
					productTagIds: productTagIds,
					productCustomerDescriptionLike: productCustomerDescriptionLike,
					productCustomCategoryPaths: productCustomCategoryPaths,
					customSort: customSort,
					size: size,
					page: page
				},
				paramsSerializer: params => {
					return qs.stringify(params, {
						arrayFormat: 'repeat',
						skipNulls: false
					});
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getProductStock (alias: string): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('private/product-stock'), {
				params: {
					alias: alias
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getSimilarProductStock (alias: string): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('private/similar-product-stocks'), {
				params: {
					alias: alias
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getCurrentOrder (
		codeLike: string,
		nameLike: string,
		status: string,
		year: string,
		storeCode: string
	): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('private/current-order'), {
				params: {
					codeLike: codeLike,
					nameLike: nameLike,
					status: status,
					year: year,
					storeCode: storeCode
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getCollectTimes (date: string): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('private/current-order/collect-times'), {
				params: {
					date: date
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getUnavailableDates (date: string): any {
		return new Promise((resolve, reject) => {
			Http.get(
				this.build('private/current-order/unavailable-dates'),
				{
					params: {
						date: date
					}
				}
			)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public changeDeliveryDate (date: string): any {
		return new Promise((resolve, reject) => {
			Http.get(
				this.build('private/current-order/change-delivery-date'),
				{
					params: {
						date: date
					}
				}
			)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public changeCollectDate (date: string): any {
		return new Promise((resolve, reject) => {
			Http.get(
				this.build('private/current-order/change-collect-date'),
				{
					params: {
						date: date
					}
				}
			)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getOrders (number: string, status: string, type: string, sendDate: string, numberStartsWith: string, size: number, page: number): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('private/orders'), {
				params: {
					number: number,
					status: status,
					type: type,
					sendDate: sendDate,
					numberStartsWith: numberStartsWith,
					size: size,
					page: page
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getOrder (number: string, status: string, type: string, sendDate: string): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('private/order'), {
				params: {
					number: number,
					status: status,
					type: type,
					sendDate: sendDate
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getUser (): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('current-user'), {
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public saveOrder (formData: any): any {
		const options = { headers: { 'Content-Type': 'application/json' } };

		return new Promise((resolve, reject) => {
			Http.post(
				this.build('private/current-order/send'),
				JSON.stringify({
					notes: formData.notes,
					_csrf: formData.csrf
				}),
				options
			)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public duplicateOrder (formData: any): any {
		return new Promise((resolve, reject) => {
			Http.post(this.build('private/duplicate-order'), formData)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public setOrderType (params: any): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('private/current-order/change-type'), {
				params: {
					type: params.type,
					shippingDestinationCode: params.shippingDestinationCode || undefined
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public setOrderStore (code: string): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('private/current-order/change-store'), {
				params: {
					storeCode: code
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public switchCustomer (code: string): any {
		const options = { headers: { 'Content-Type': 'application/json' } };
		return new Promise((resolve, reject) => {
			Http.post(this.build('private/switch-customer'),
				JSON.stringify({
					customerCode: code
				}),
				options
			)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public passwordChange (formData: any): any {
		const options = { headers: { 'Content-Type': 'application/json' } };
		return new Promise((resolve, reject) => {
			Http.post(this.build('private/password-change'),
				JSON.stringify({
					oldPassword: formData.oldPassword,
					newPassword: formData.newPassword,
					confirmPassword: formData.confirmPassword,
					_csrf: formData.csrfToken
				}), options)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public passwordForgotten (formData: any): any {
		const options = { headers: { 'Content-Type': 'application/json' } };
		return new Promise((resolve, reject) => {
			Http.post(this.build('password-forgotten'),
				JSON.stringify({
					email: formData.email,
					_csrf: formData.csrfToken
				}), options)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public passwordExpired (formData: any): any {
		const options = { headers: { 'Content-Type': 'application/json' } };
		return new Promise((resolve, reject) => {
			Http.post(this.build('password-expired'),
				JSON.stringify({
					email: formData.email,
					oldPassword: formData.oldPassword,
					newPassword: formData.newPassword,
					confirmPassword: formData.confirmPassword,
					_csrf: formData.csrfToken
				}), options)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public registration (formData: any): any {
		const options = { headers: { 'Content-Type': 'application/json' } };
		return new Promise((resolve, reject) => {
			Http.post(this.build('registration'),
				JSON.stringify({
					firstName: formData.firstName,
					lastName: formData.lastName,
					email: formData.email,
					customerCode: formData.customerCode,
					customerVatNumberOrTaxCode: formData.customerVatNumberOrTaxCode,

					customerName: formData.customerName,
					customerStoreCode: formData.customerStoreCode,
					customerVatNumber: formData.customerVatNumber,
					customerTaxCode: formData.customerTaxCode,
					customerProvinceCode: formData.customerProvinceCode,
					customerCity: formData.customerCity,
					customerPostalCode: formData.customerPostalCode,
					customerAddress: formData.customerAddress,
					customerPhone: formData.customerPhone,
					customerFax: formData.customerFax,
					customerEmail: formData.customerEmail,

					_csrf: formData.csrfToken
				}), options)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public profileUpdate (formData: any): any {
		const options = { headers: { 'Content-Type': 'application/json' } };
		return new Promise((resolve, reject) => {
			Http.post(this.build('private/profile-update'),
				JSON.stringify({
					firstName: formData.firstName,
					lastName: formData.lastName,
					address: formData.address,
					birthDate: formData.birthdate,
					taxCode: formData.taxCode,
					gender: formData.gender,
					city: formData.city,
					postalCode: formData.postalCode,
					mobile: formData.mobile,
					phone: formData.phone,
					fax: formData.fax,
					province: formData.province,
					_csrf: formData.csrfToken
				}), options)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public login (formData: FormData): any {
		const options = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Basic ${window.btoa('cc-client:XY7kmzoNzl100')}`
			}
		};
		return new Promise((resolve, reject) => {
			Http.post(this.loginTokenPath(), formData, options)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public logout (): Promise<any> {
		const options = {
			headers: {
				'Authorization': `Basic ${window.btoa('cc-client:XY7kmzoNzl100')}`,
				'Revoke-Authorization': `Bearer ${this.getBearerAccessToken()}`
			}
		};
		return new Promise((resolve, reject) => {
			Http.delete(this.loginTokenPath(), options)
				.then((response) => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public refreshToken (formData: FormData): any {
		const options = {
			headers: {
				'Content-Type': 'application/x-www-form-urlencoded',
				'Authorization': `Basic ${window.btoa('cc-client:XY7kmzoNzl100')}`
			}
		};
		return new Promise((resolve, reject) => {
			Http.post(this.loginTokenPath(), formData, options)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getProvinces (): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('provinces'), {
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getBrands (): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('brands'), {})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getCustomers (vatNumberOrTaxCode: string) : any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('customers'), {
				params: {
					vatNumberOrTaxCode: vatNumberOrTaxCode
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getSecondaryCustomers (params: any) : any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('current-user/secondary-customers'), {
				params: {
					query: params.query || undefined,
					page: params.page || undefined
				}
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public sendInformation (formdata: FormData): any {
		return new Promise((resolve, reject) => {
			Http.post(this.build('information'), formdata)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public emptycart (): any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('private/current-order/empty'), {})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public setDefaultHeaders () {
		const barearAccessToken: string = this.getBearerAccessToken();
		Http.defaults.headers.common = {};

		if (barearAccessToken != null) {
			Http.defaults.headers.common = {
				'Authorization': `Bearer ${barearAccessToken}`
			};
		}
		const csrfHeaderToken: string = this.getCsrfHeaderToken();
		const csrfToken: string = this.getCsrfToken();
		if ((csrfHeaderToken != null) && (csrfToken != null)) {
			Http.defaults.headers.common = {
				[csrfHeaderToken]: csrfToken
			};
		}
	}

	public createShippingDestinations (params: any): any {
		const options = { headers: { 'Content-Type': 'application/json' } };

		return new Promise((resolve, reject) => {
			Http.post(
				this.build('private/shipping-destinations'),
				JSON.stringify({
					name: params.name,
					provinceCode: params.provinceCode,
					city: params.city,
					postalCode: params.postalCode,
					address: params.address,
					phone: params.phone,
					mobile: params.mobile,
					email: params.email,
					fax: params.fax,
					notes: params.notes
				}),
				options
			)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public updatesetShippingDestinations (params: any): any {
		const options = { headers: { 'Content-Type': 'application/json' } };

		return new Promise((resolve, reject) => {
			Http.put(
				this.build(`private/shipping-destinations/${params.code}`),
				JSON.stringify({
					name: params.name,
					provinceCode: params.provinceCode,
					city: params.city,
					postalCode: params.postalCode,
					address: params.address,
					phone: params.phone,
					mobile: params.mobile,
					fax: params.fax,
					email: params.email,
					notes: params.notes
				}),
				options
			)
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public deleteShippingDestinations (params: any): any {
		return new Promise((resolve, reject) => {
			Http.delete(this.build(`private/shipping-destinations/${params.code}`), {
			})
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	public getTags () : any {
		return new Promise((resolve, reject) => {
			Http.get(this.build('product-tags'))
				.then(response => {
					resolve(response.data);
				})
				.catch(error => {
					reject(error);
				});
		});
	}

	private build (endpoint: string): string {
		return (this.getApiPath() !== '') ? `${this.getApiPath()}/${Api.BASE_API_PATH}/${endpoint}` : `${this.getContextPath()}/${Api.BASE_API_PATH}/${endpoint}`;
	}

	private loginTokenPath (): string {
		return (this.getApiPath() !== '') ? this.getApiPath().concat('/oauth/token') : this.getContextPath().concat('/oauth/token');
	}

	private getContextPath (): string {
		const contextPath: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_context_path')[0]
		);
		return contextPath ? contextPath.content : '';
	}

	private getApiPath (): string {
		const apiPath: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_api_path')[0]
		);
		return apiPath ? apiPath.content : '';
	}

	private getCsrfToken (): string {
		const csrfCode: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_csrf')[0]
		);
		return csrfCode ? csrfCode.content : null;
	}

	private getCsrfHeaderToken (): string {
		const csrfHeader: HTMLMetaElement = <HTMLMetaElement>(
			document.getElementsByName('_csrf_header')[0]
		);
		return csrfHeader ? csrfHeader.content : null;
	}

	private getBearerAccessToken (): string {
		return localStorage.getItem(this.getContextPath().replace('/', '').concat('.cc_shop_bearer_access_token'));
	}
}

export { Api };
